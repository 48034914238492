import Image from "next/image"
import Link from "next/link"
import ComponentsImage from "./categories/box-components.jpeg"
import FabricsImage from "./categories/box-fabrics.jpeg"
import PosImage from "./categories/box-pos.jpeg"
import ToolingImage from "./categories/box-tooling.jpeg"
import ComponentsIcon from "./categories/icon-components.svg"
import FabricsIcon from "./categories/icon-fabrics.svg"
import PosIcon from "./categories/icon-pos.svg"
import ToolingIcon from "./categories/icon-tooling.svg"
import styles from "./categories.module.scss"
import SectionTitle from "../../components/section-title"
import FeatherArrowRightIcon from "../../svgs/feather-arrow-right.svg"

const data = [
  {
    title: "Fabrics",
    image: FabricsImage,
    icon: FabricsIcon,
    url: "/fabrics",
  },
  {
    title: "Components",
    image: ComponentsImage,
    icon: ComponentsIcon,
    url: "/components",
  },
  {
    title: "Tooling",
    image: ToolingImage,
    icon: ToolingIcon,
    url: "/tooling",
  },
  {
    title: "Point of Sale",
    image: PosImage,
    icon: PosIcon,
    url: "/point-of-sale",
  },
]

const Categories = () => {
  return (
    <section className="bg-gray u-section-padding pt-4 pt-md-0">
      <div className="container">
        <SectionTitle className="text-center">Shop by category</SectionTitle>

        <div className="row mb-n3 mb-md-0">
          {data.map((item) => (
            <div className="col-12 col-md-3 mb-3 mb-md-0" key={item.title}>
              <Link href={item.url} className={styles.box}>
                <div className={styles.image}>
                  <Image src={item.image} alt={item.title} fill />
                  <div className={styles.inner}>
                    <div className={styles.icon}>
                      <item.icon />
                    </div>
                    <p className={styles.title}>{item.title}</p>
                  </div>
                </div>
                <p className={styles.link}>
                  View products <FeatherArrowRightIcon />
                </p>
              </Link>
            </div>
          ))}
        </div>
      </div>
    </section>
  )
}

export default Categories
