import { useEffect, useRef } from "react"
import clsx from "clsx"
import gsap from "gsap"
import Link from "next/link"
import Slider, { CustomArrowProps, Settings } from "react-slick"
import Image3 from "./hero/bg-hero-components.jpeg"
import Image2 from "./hero/bg-hero-fabrics.jpeg"
import Image5 from "./hero/bg-hero-pos.jpeg"
import Image4 from "./hero/bg-hero-tooling.jpeg"
import Image1 from "./hero/bg-hero-vision.jpeg"
import S from "./hero.module.scss"
import ArrowLeftIcon from "../../svgs/feather-arrow-left.svg"
import ArrowRightIcon from "../../svgs/feather-arrow-right.svg"

const slides = [
  {
    background: Image1,
    title: "New Vision Card®",
    content:
      "<p>Louvolite’s new Vision® fabric collection is packed full of sophisticated weaves, organic textured designs and eye catching prints showcased in a brand new fabric book featuring sensational roomset photography.</p>",
    button: {
      url: "/point-of-sale/vision-card-louvolite-2",
      title: "View product",
    },
  },
  {
    background: Image2,
    title: "Fabrics",
    content: "<p>Order fabrics from all our collections.</p>",
    button: {
      url: "/fabrics",
      title: "View products",
    },
  },
  {
    background: Image3,
    title: "Components",
    content:
      "<p>Order all the parts you need to make blinds and shutters..</p>",
    button: {
      url: "/components",
      title: "View products",
    },
  },
  {
    background: Image4,
    title: "Tooling",
    content:
      "<p>Order the tooling required to assemble your blinds & shutters.</p>",
    button: {
      url: "/tooling",
      title: "View products",
    },
  },
  {
    background: Image5,
    title: "Point of Sale",
    content:
      "<p>Order pattern books, brochures, leaflets and other promotional literature</p>",
    button: {
      url: "/point-of-sale",
      title: "View products",
    },
  },
]

const ArrowLeft = (props: CustomArrowProps) => {
  return (
    <button
      type="button"
      onClick={props.onClick}
      style={{ ...props.style }}
      className={clsx(props.className, S.arrow, S.left)}
      aria-label="Previous"
    >
      <ArrowLeftIcon />
    </button>
  )
}

const ArrowRight = (props: CustomArrowProps) => {
  return (
    <button
      type="button"
      onClick={props.onClick}
      style={{ ...props.style }}
      className={clsx(props.className, S.arrow, S.right)}
      aria-label="Next"
    >
      <ArrowRightIcon />
    </button>
  )
}

const IndexHero = () => {
  const slidesRef = useRef<HTMLDivElement[]>([])

  const slideAnimation = (slideNumber = 0) => {
    const slide = slidesRef.current[slideNumber]
    const slideTl = gsap.timeline()

    slideTl.fromTo(
      slide.querySelector("[data-gsap-title]"),
      { autoAlpha: 0, y: -25 },
      { autoAlpha: 1, y: 0 },
    )

    slideTl.fromTo(
      slide.querySelector("[data-gsap-content]"),
      { autoAlpha: 0, y: 25 },
      { autoAlpha: 1, y: 0 },
    )

    slideTl.fromTo(
      slide.querySelector("[data-gsap-button]"),
      { autoAlpha: 0, y: 5 },
      { autoAlpha: 1, y: 0 },
    )
  }

  const settings: Settings = {
    nextArrow: <ArrowRight />,
    prevArrow: <ArrowLeft />,
    infinite: false,
    autoplay: true,
    autoplaySpeed: 5000,
    beforeChange: (_: number, nextSlide: number) => slideAnimation(nextSlide),
  }

  useEffect(slideAnimation, [])

  return (
    <Slider {...settings} className={clsx(S.slider, "equal")}>
      {slides.map((item, index) => (
        <div key={item.title}>
          <div
            ref={(el) => {
              if (el) {
                slidesRef.current[index] = el
              }
            }}
            className={S.slide}
            style={{ backgroundImage: `url(${item.background.src})` }}
          >
            <div className="container">
              <h1 data-gsap-title className={S.title}>
                {item.title}
              </h1>
              {item.content && (
                <div
                  data-gsap-content
                  className={clsx(S.content, "wysiwyg")}
                  dangerouslySetInnerHTML={{ __html: item.content }}
                />
              )}
              {item.button && (
                <div data-gsap-button className="text-center mt-4 mt-md-5">
                  <Link href={item.button.url} className="btn btn-primary">
                    {item.button.title}
                  </Link>
                </div>
              )}
            </div>
          </div>
        </div>
      ))}
    </Slider>
  )
}

export default IndexHero
