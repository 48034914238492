import clsx from "clsx"
import Lottie from "react-lottie"
import { createBreakpoint } from "react-use"
import S from "./process.module.scss"
import Animation from "../../animations/01_LoveBlindsLoveLouvolite_DESK.json"
import AnimationMobile from "../../animations/01_LoveBlindsLoveLouvolite_MOB.json"
import SectionTitle from "../../components/section-title"

const useBreakpoint = createBreakpoint()

const ProcessGraph = () => {
  const breakpoint = useBreakpoint()

  return (
    <section className={clsx("bg-gray u-section-padding-md")}>
      <div className="container">
        <SectionTitle className="text-center">
          Welcome to the
          <br className="d-sm-none" />
          <span className="d-none d-sm-inline">&nbsp;</span>
          Louvolite Trade Portal.
        </SectionTitle>
      </div>

      <div className={S.container}>
        {breakpoint !== "tablet" && (
          <Lottie options={{ animationData: Animation }} />
        )}
        {breakpoint === "tablet" && (
          <Lottie options={{ animationData: AnimationMobile }} />
        )}
      </div>
    </section>
  )
}

export default ProcessGraph
