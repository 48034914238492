import Layout from "components/layout"
import IndexHero from "../partials/index/hero"
import ProcessGraph from "../partials/index/process"
import RecentlyOrderedProducts from "../partials/index/recently-ordered-products"
import Categories from "@/partials/index/categories"

const Home = () => {
  return (
    <Layout title="Home">
      <IndexHero />
      <ProcessGraph />
      <Categories />
      <RecentlyOrderedProducts />
    </Layout>
  )
}

export default Home
