import { useQuery } from "@tanstack/react-query"
import SectionTitle from "../../components/section-title"
import Preloader from "@/components/preloader"
import ProductBox from "@/components/product-box"
import API from "@/lib/api"
import useUser from "@/lib/useUser"
import { Product } from "@/types"

const RecentlyOrderedProducts = () => {
  const { user } = useUser()
  const { data, isLoading, isFetched } = useQuery<Product[], Error>({
    queryKey: ["recentlyOrdered", user?.access_token!],
    queryFn: () => API.services.products.recentlyOrdered(user?.access_token!),
  })

  return (
    <section className="bg-gray u-section-padding pt-4 pt-md-0">
      <div className="container">
        <SectionTitle className="text-center">Recently ordered</SectionTitle>

        {isLoading && <Preloader className="mx-auto" />}
        {isFetched && data && (
          <div className="row">
            {data.map((item) => (
              <div className="col-6 col-md-3" key={item.id}>
                <ProductBox data={item} />
              </div>
            ))}
          </div>
        )}
      </div>
    </section>
  )
}

export default RecentlyOrderedProducts
