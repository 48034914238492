import { FunctionComponent } from "react"
import clsx from "clsx"

type Props = {
  className?: string
  pure?: boolean
  theme?: "white" | "normal"
}

const SectionTitle: FunctionComponent<React.PropsWithChildren<Props>> = ({
  children,
  className,
  pure = false,
  theme = "normal",
  ...props
}) => (
  <div
    className={clsx("u-section-title", { pure }, `theme-${theme}`, className)}
    {...props}
  >
    <p>{children}</p>
  </div>
)

export default SectionTitle
