import clsx from "clsx"
import S from "./preloader.module.scss"

type Props = {
  className?: string
}

const Preloader: React.FC<Props> = ({ className = "" }) => {
  return (
    <div className={clsx(S.preloader, className)}>
      <div className={S.dot} />
      <div className={S.dot} />
    </div>
  )
}

export default Preloader
